import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Outlet 
} from 'react-router-dom';
import { AppContainer } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import V2Landing from './views/V2Landing';

function App() {

  // const dispatch = useDispatch();
  // const toast = useToast();


  // const [authCheckCompleted, setAuthCheckCompleted] = useState(false);

  // const stripeCustomerId = useSelector((state) => state?.userData?.loginInfo?.stripeCustomerId);
  // const userInfo = useSelector((state) => state?.userData?.loginInfo);

  // const PrivateRoutes = () => {
  //   if(!authCheckCompleted){
  //     return null;
  //   };

  //   return userInfo?.user_id && <Outlet/>;
  // };

  // useEffect(() => {
  //   setAuthCheckCompleted(true);
  // }, [userInfo?.user_id]);
  
  
  return (
    <Router>
      <AppContainer>
			  <CssBaseline />
        <Routes>
          <Route path = '/' element = { <V2Landing   /> } />

          {/* <Route element  =  { <PrivateRoutes /> }>
            <Route       path = "/catalog"       element  =  { <Catalog />       } />    
          </Route>         */}
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
