import { 
  Button, 
  Grid, 
  Toolbar, 
  Typography 
} from "@mui/material";
import { StyledAppBar } from "./styled";
import { headerLogoSrc } from "./consts";

export default function AppBarMenu({ isMobile, navigate }){

  return (
    <StyledAppBar position="fixed" open={true} elevation={0} component="nav">
      <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
            width: isMobile ? '100%' : '50%',
            border: '2px solid rgba(82,193,221, 0.15)',
            padding: '1rem',
            paddingRight: isMobile ? '1rem' : '2rem',
            borderRadius: '3rem',
            backgroundColor: '#182225',
            filter: 'drop-shadow(0px 0px 50px rgba(82,193,221, 0.3))'
          }}
        >
          <Grid item sx={{flex: '1', display: 'flex', justifyContent: isMobile ? 'flex-start' : 'center', alignItems: 'center', flexWrap: 'nowrap', flexDirection: 'row'}}>
            <img height={ isMobile ? '60rem' : '45rem'} src={headerLogoSrc} alt='logo' />
          </Grid>
          {
            !isMobile ? (
              <Grid 
                item
                sx={{
                  display: 'flex',
                  flex: '4',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                <Button href='#features'> 
                  <Typography variant="h6" sx={{color: 'white', fontWeight: '500'}}>
                    Why Us?
                  </Typography>
                </Button>
                <Button href='#process'> 
                  <Typography variant="h6" sx={{color: 'white', fontWeight: '500'}}>
                    Our Process
                  </Typography>
                </Button>
                <Button href='#consult'> 
                  <Typography variant="h6" sx={{color: 'white', fontWeight: '500'}}>
                    Free Consult
                  </Typography>
                </Button>
              </Grid>
            ) : (
              <Grid 
                item
                sx={{
                  display: 'flex',
                  flex: '2',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button variant='contained' href='#consult' sx={{borderRadius: '3rem', backgroundColor: '#2394c3'}}> 
                  <Typography sx={{color: 'white', fontWeight: '500', padding: '0.5rem', fontSize: '1rem'}}>
                    Free Consult
                  </Typography>
                </Button>
              </Grid>
            )
          }
        </Grid>
      </Toolbar>
    </StyledAppBar>
  )
}