import { Typography } from "@mui/material";

export const pageHeaderContent = `New medical software does incredible things, but creating an easy beautiful UI/UX is difficult...we make it easy.`;

export const featureBoxOne = {
  headerText: 'Trusted By Enterprise',
  content: "We've had the pleasure of building and refining UI / UX experiences for some of the biggest brands around"
};

export const screenFeatureOne = {
  headerText: 'Simplify Complexity',
  content: 'We have years of experience utilzing huge data sets to create intuitive easy to use user interfaces.'
};

export const screenFeatureTwo = {
  headerText: 'Medically Focused',
  content: 'The medical industry is subject to numerous specific regulations governing access to records, necessitating a specialized team to effectively develop within the legal framework surrounding medical data.'
};

export const screenFeatureThree = {
  headerText: 'Precise Results',
  content: "In software development, features often miss the mark. With our deep experience in complex projects, we excel at identifying true needs and delivering precise solutions that address real challenges."
};

export const emailSubscription = {
  headerText: 'Request A Consultation',
  content: `We're eager to explore how we can assist you. Please enter your email, and a team member will contact you to start your journey toward the best solutions!`
};

export const heroImageSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713848699/Vortex%20Media%20Portfolio/vortexportfolio2image_gjozkq.png';
export const screenFeatureOneSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714095856/Vortex%20Media%20Portfolio/asset-generation-981f4591-3e3e-4041-aa55-fd3e1990c706-0_cnay17.png';
export const screenFeatureTwoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714097402/Vortex%20Media%20Portfolio/medicalfocused_b3d4ng.png';
export const screenFeatureThreeSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714098540/Vortex%20Media%20Portfolio/teamwork_ynlb3j.png';
export const infoGraphSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714155437/Vortex%20Media%20Portfolio/newprocess_qwgv3v.png';
export const footerLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713899582/vortexmedialogo_dtmrjw.png';
export const headerLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713899582/vortexmedialogo_dtmrjw.png';

export const armyLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714005941/Vortex%20Media%20Portfolio/US_Army_Star_Logo_SSI_1_kahrno.png';
export const bioplusLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714005985/Vortex%20Media%20Portfolio/cropped-logo-1_edn186.png';
export const elevanceLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714005940/Vortex%20Media%20Portfolio/Elevance-Health-Logo-400x200-1_1_ir6e8c.png';
export const wellsfargoLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714005941/Vortex%20Media%20Portfolio/Wells_Fargo_Bank_svg_e26ncd.png';
export const vmwareLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714005940/Vortex%20Media%20Portfolio/58482af2cef1014c0b5e4a20_1_w1bivs.png';
export const anaplanLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714005939/Vortex%20Media%20Portfolio/anaplan-press-release_du6erm.png';


export const processStepsMobile = [
  {
    id: 0,
    headerText: 'Gather Requirements',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714165010/Vortex%20Media%20Portfolio/step1vortex_buvylo.png',
    content: 'Gathering precise requirements is crucial, so we focus heavily on this step to ensure a smooth process thereafter.'
  },
  {
    id: 1,
    headerText: 'DevOps Planning',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714165013/Vortex%20Media%20Portfolio/step2vortex_cr0q37.png',
    content: 'Next, we establish a clear execution plan that facilitates easy status updates.'
  },
  {
    id: 2,
    headerText: 'Design & Refine',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714165014/Vortex%20Media%20Portfolio/step3vortex_kxoast.png',
    content: 'Our designers craft the initial vision, then collaborate with the client and team for further refinement before handing off the design to development.'
  },
  {
    id: 3,
    headerText: 'Development',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714165015/Vortex%20Media%20Portfolio/step4vortex_ztq9cz.png',
    content: 'If all prior steps are executed correctly, this final phase of building and launching the project becomes remarkably straightforward.'
  },
  {
    id: 4,
    headerText: 'Retrospective & Changes',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1714165011/Vortex%20Media%20Portfolio/step5vortex_cayqmw.png',
    content: 'After presenting the finished product, we review the final steps to identify and implement any necessary adjustments.'
  },
]
export const HeroHeaderText = (isMobile) => {
  console.log('isMobile', isMobile.isMobile)
  return (
    <Typography variant={ isMobile ? 'h3' : 'h2'} color='white' sx={{width: '100%', textAlign: isMobile.isMobile ? 'center' : 'right', fontWeight: '700'}}>
      Medical {isMobile.isMobile && (<br />)} UI / UX <span style={{color: '#52c1dd', fontFamily: '"Edu VIC WA NT Beginner", cursive'}}> Development Specialists </span> 
    </Typography>
  )
}