import React, { useRef, useState } from 'react';
import { 
  Grid, 
  Typography,
  useMediaQuery,
  TextField,
  IconButton,
  Divider,
  Button,
  CircularProgress,
  Container,
  Card
} from '@mui/material';
import { 
  EmailSubGridContainer,
  EmailSubSubmitButton,
  EmailSubTextGridItem,
  FeatureBox,
  FeatureBoxContentGrid,
  FeatureContainerBox,
  FeatureImageCard,
  FeaturePaper, 
  FooterBox, 
  FooterContentGridContainer, 
  FooterLogoGridItem, 
  FooterSocialLinksContainer, 
  HeaderTextGridItem, 
  HeroBox, 
  HeroGridContainer, 
  HeroHeaderContentText, 
  IndividualScreenFeatureGridItem, 
  InnerBox, 
  MainPageBox, 
  ScreenFeatureBoxInnerGridContainer, 
  ScreenFeatureContentContainer, 
  ScreenFeatureContentItem, 
  ScreenFeatureHeaderTypography, 
  SecondaryPageBox, 
  SocialProofGridContainer, 
  SocialProofLogoCardLarge, 
  SocialProofLogoCardMedium, 
  SocialProofLogoCardSmall, 
  SocialProofLogosContainer, 
  VideoBox 
} from './styled';
import { useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import AppBarMenu from './AppBarMenu';
import { 
  HeroHeaderText, 
  armyLogoSrc, 
  bioplusLogoSrc, 
  elevanceLogoSrc, 
  emailSubscription, 
  featureBoxOne, 
  footerLogoSrc, 
  heroImageSrc, 
  infoGraphSrc, 
  pageHeaderContent, 
  processStepsMobile, 
  screenFeatureOne, 
  screenFeatureOneSrc, 
  screenFeatureThree, 
  screenFeatureThreeSrc, 
  screenFeatureTwo, 
  screenFeatureTwoSrc, 
  vmwareLogoSrc, 
  wellsfargoLogoSrc
} from './consts';
import { useDispatch, useSelector } from 'react-redux';
import { saveKlaviyoSubscribe } from '../../actions/klaviyo';
// import { saveKlaviyoSubscribe } from '../../actions/klaviyo';


function V2Landing() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollRef = useRef();

  const [email, setEmail] = useState('');
  const klaviyoSubscription = useSelector((state) => state?.klaviyo);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(saveKlaviyoSubscribe(email, dispatch));
  };  

	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  
  const handleSubmitFormDisplay = () => {
    console.log('klaviyo sub', klaviyoSubscription)
    if(klaviyoSubscription?.isLoading){
      return (
        <Container 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid lightgray', 
            padding: '1rem', 
            borderRadius: '1rem'
          }}
        >
          <CircularProgress color='secondary' />
        </Container>
      )
    };

    if(klaviyoSubscription?.error){
      return (
        <Container sx={{border: '1px solid lightgray', padding: '1rem', borderRadius: '1rem'}}>
          <Typography variant='h6' textAlign='center' fontWeight='700' sx={{color: 'black'}}>
            Looks like we have your email, we will be in contact shortly!
          </Typography>
        </Container>
      )
    };

    if(klaviyoSubscription?.email){
      return (
        <Container sx={{border: '1px solid lightgray', padding: '1rem', borderRadius: '1rem'}}>
          <Typography variant='h6' color='secondary' textAlign='center' fontWeight='700'>
            Your Email Was Saved! <br /> We will be reaching out to you soon!
          </Typography>
        </Container>
      )
    }

    return (
      <form 
        noValidate 
        autoComplete="off" 
        onSubmit={handleSubmit} 
        style={{
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row'
        }}
      >
        <TextField 
          id="email" 
          label="Email" 
          variant="outlined" 
          onChange={handleEmailChange} 
          sx={{
            width: isMobile ? '100%' : '25rem',
            maxWidth: '25rem',
            marginRight: isMobile ? '0' : '1rem',
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
            marginBottom: isMobile ? '2rem' : '',
          }} 
        />
        <EmailSubSubmitButton variant="contained" type="submit">
          Submit
        </EmailSubSubmitButton>
      </form>
    )
  }

  const handleProcessDisplay = () => {
    if(isMobile){
      return processStepsMobile.map((step) => (
        <Grid key={step.id} container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem', paddingTop: '0', flexDirection: 'column', minHeight: '5rem'}}>
          <Grid item sx={{padding: '1rem'}}>
            <Typography variant='h5' sx={{textAlign: 'center', color: 'white'}}>
              {step.headerText}
            </Typography>
          </Grid>
          <Grid item sx={{padding: '1rem'}}>
            <img height='200rem' src={step.imageSrc} alt='stepImage' style={{minHeight: '5rem'}}/>
          </Grid>
          <Grid item sx={{width: '75%'}}>
            <Typography sx={{textAlign: 'center', marginBottom: '2rem', color: 'white'}}>
              {step.content}
            </Typography>
          {
            step.id < 4 && (
              <Divider variant='middle' sx={{borderColor: '#959595'}} />
            )
          }
          </Grid>
        </Grid>
      ))
    };

    return (
      <Grid item sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img height='650rem' src={infoGraphSrc} alt='infograph' style={{minHeight: '5rem' }}/>
      </Grid>
    )
  }

  return (
    <MainPageBox>
      <AppBarMenu isMobile={isMobile} navigate={navigate}/>
      <SecondaryPageBox>
        {/* First Section */}
        <HeroBox>
          <HeroGridContainer container>
            <HeaderTextGridItem item sx={{width: '100%'}}>
              <HeroHeaderText isMobile={isMobile} />
              <HeroHeaderContentText color={'white'} variant='h6'>
                {pageHeaderContent}
              </HeroHeaderContentText>
            </HeaderTextGridItem>
            <Grid item sx={{flex: '2', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img 
                width={isMobile ? '300rem' : '750rem'} 
                height={isMobile ? '175rem' : '400rem'} 
                src={heroImageSrc} 
                alt='hero image' 
              />
            </Grid>
          </HeroGridContainer>
        </HeroBox>
        {/* Second Section */}
        <FeatureContainerBox id='howitworks'>
          <InnerBox>
            <FeaturePaper elevation={10}>
              <FeatureBoxContentGrid container>
                <SocialProofLogosContainer container>
                  <Grid item>
                    <SocialProofLogoCardSmall>
                      <img src={armyLogoSrc} alt='army logo' height={100} />
                    </SocialProofLogoCardSmall>
                  </Grid>
                  <Grid item>
                    <SocialProofLogoCardMedium>
                      <img src={vmwareLogoSrc} alt='army logo' height={60} width={250} style={{padding: '0.5rem'}}/>
                    </SocialProofLogoCardMedium>
                  </Grid>
                  <Grid item>
                    <SocialProofLogoCardLarge>
                      <img src={elevanceLogoSrc} alt='army logo' height={150} width={300}/>
                    </SocialProofLogoCardLarge>
                  </Grid>
                  <Grid item>
                    <SocialProofLogoCardMedium>
                      <img src={bioplusLogoSrc} alt='army logo' height={75} width={275}/>
                    </SocialProofLogoCardMedium>
                  </Grid>
                  <Grid item>
                    <SocialProofLogoCardSmall>
                      <img src={wellsfargoLogoSrc} alt='army logo' height={100} />
                    </SocialProofLogoCardSmall>
                  </Grid>
                  
                </SocialProofLogosContainer>
                <SocialProofGridContainer container>
                  <Typography 
                    sx={{
                      fontWeight: '400', 
                      paddingTop: '3rem',
                      color: '#ffffff',
                      fontFamily: '"Edu VIC WA NT Beginner", cursive',
                      fontSize: '3.5rem',
                      textAlign: isMobile && ('center')
                    }}
                  >
                    {featureBoxOne.headerText}
                  </Typography>
                </SocialProofGridContainer>
              </FeatureBoxContentGrid>
            </FeaturePaper>
          </InnerBox>
        </FeatureContainerBox>
        {/* Third Section */}
        <FeatureBox id='features'>
          <ScreenFeatureBoxInnerGridContainer container>
            <IndividualScreenFeatureGridItem>
              <ScreenFeatureContentContainer container>
                <ScreenFeatureContentItem item>
                  <ScreenFeatureHeaderTypography variant={isMobile ? 'h4' : 'h3'}>
                    {screenFeatureOne.headerText}
                  </ScreenFeatureHeaderTypography>
                  <Typography variant='h6' sx={{textAlign: isMobile ? 'center' : 'left', color: 'white'}}>
                    {screenFeatureOne.content}
                  </Typography>
                </ScreenFeatureContentItem>
              </ScreenFeatureContentContainer>
              <FeatureImageCard>
                <img src={screenFeatureOneSrc} alt='featureTwo' style={{minHeight: '5rem', height: isMobile ? '20rem' : '30rem', width: '100%'}}/>
              </FeatureImageCard>
            </IndividualScreenFeatureGridItem>
            <IndividualScreenFeatureGridItem item sx={{ flexDirection: isMobile && 'column-reverse!important' }}>
              <FeatureImageCard>
                <img src={screenFeatureTwoSrc} alt='featureTwo' style={{minHeight: '5rem', height: isMobile ? '20rem' : '30rem', width: '100%'}}/>
              </FeatureImageCard>
              <ScreenFeatureContentContainer container>
                <ScreenFeatureContentItem item>
                  <ScreenFeatureHeaderTypography variant={isMobile ? 'h4' : 'h3'}>
                    {screenFeatureTwo.headerText}
                  </ScreenFeatureHeaderTypography>
                  <Typography variant='h6' sx={{textAlign: isMobile ? 'center' : 'left', color: 'white'}}>
                    {screenFeatureTwo.content}
                  </Typography>
                </ScreenFeatureContentItem>
              </ScreenFeatureContentContainer>
            </IndividualScreenFeatureGridItem>
            <IndividualScreenFeatureGridItem item>
              <ScreenFeatureContentContainer container>
                <ScreenFeatureContentItem item>
                  <ScreenFeatureHeaderTypography variant={isMobile ? 'h4' : 'h3'}>
                    {screenFeatureThree.headerText}
                  </ScreenFeatureHeaderTypography>
                  <Typography variant='h6' sx={{textAlign: isMobile ? 'center' : 'left', color: 'white'}}>
                    {screenFeatureThree.content}
                  </Typography>
                </ScreenFeatureContentItem>
              </ScreenFeatureContentContainer>
              <FeatureImageCard>
                <img src={screenFeatureThreeSrc} alt='featureTwo' style={{minHeight: '5rem', height: isMobile ? '20rem' : '30rem', width: '100%'}}/>
              </FeatureImageCard>
            </IndividualScreenFeatureGridItem>
          </ScreenFeatureBoxInnerGridContainer>
        </FeatureBox>
        {/* Fourth Section */}
        <VideoBox id='process'>
          <Card 
            container
            sx={{
              display: 'flex',
              flexDirection: 'column', 
              justifyContent: 'space-around',
              width: '100%',
              height: '100%',
              borderRadius: '5rem',
              backgroundColor: 'rgba(133,126,243, 0.335)',
              margin: '0 5rem',
              // filter: 'drop-shadow(0px 0px 100px #857EF3)',
              minHeight: '5rem'
            }}
          >
            <Grid item sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', height: '10rem', width: '100%', minHeight: '5rem'}}>
              <Typography variant='h2' sx={{textAlign: isMobile ? 'center' : 'left', color: '#ffffff', fontFamily: '"Edu VIC WA NT Beginner", cursive'}}>
                Our Process
              </Typography>
              <Divider variant='middle' sx={{color: 'orange', width: '50%', borderColor: '#ffffff'}}/>
            </Grid>

            { handleProcessDisplay() }
          </Card>
        </VideoBox>
        {/* Fifth Section */}
        <FeatureContainerBox id='process' sx={{padding: '5rem 0', backgroundColor: '#f6f8fa'}}>
          <EmailSubGridContainer container>
            <EmailSubTextGridItem item>
              <ScreenFeatureHeaderTypography variant={isMobile ? 'h5' : 'h4'} sx={{color: 'black'}}>
                {emailSubscription.headerText}
              </ScreenFeatureHeaderTypography>
              <Typography sx={{textAlign: 'center', color: 'gray'}}>
                {emailSubscription.content}
              </Typography>
            </EmailSubTextGridItem>
            <Grid item sx={{width: '100%', paddingTop: '2rem'}} id='subscribe'>
              { handleSubmitFormDisplay() }
            </Grid>
          </EmailSubGridContainer>
        </FeatureContainerBox>
        {/* Footer */}
        <FooterBox id='consult'>
          <FooterContentGridContainer container>
            <FooterLogoGridItem item>
              {
                !isMobile && (
                  <img height='50rem' src={footerLogoSrc} alt='logo' />
                )
              }
              <Typography variant={ isMobile ? 'h5' : 'h6'} sx={{color: 'white', marginLeft: '1rem', fontWeight: '500' }}>
                Vortex Media Consulting
              </Typography>
            </FooterLogoGridItem>
            <FooterSocialLinksContainer container>
              <Grid item>
                <IconButton 
                  size='large'
                  sx={{backgroundColor: '#ffffff'}}
                  href='https://www.instagram.com/chris_rappa/'
                >
                  <InstagramIcon sx={{color: '#028fb6', fontSize: '2rem'}}/>
                </IconButton>
              </Grid>
            </FooterSocialLinksContainer>
          </FooterContentGridContainer>
        </FooterBox>
      </SecondaryPageBox>
    </MainPageBox>
  )
}

export default V2Landing