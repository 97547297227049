import { 
  applyMiddleware , 
  combineReducers , 
  compose         , 
  createStore
} from "redux";
import thunk from "redux-thunk";
import { klaviyoReducer } from "./reducers/klaviyoReducer";

const initialState = {
  klaviyo: {
    email: null,
    isLoading: false,
    error: null
  }
};

const reducers = combineReducers({
  klaviyo     : klaviyoReducer
});

const composeEnhancer = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true})) || compose;
const store = createStore(reducers, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;